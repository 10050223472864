@import '../../../../scss/theme-bootstrap';

.header-offers-panel-item {
  height: 100%;
  padding: 85px 20px 50px;
  @include breakpoint($large-up) {
    padding: 105px 50px 50px;
  }
  &__header,
  &__copy {
    font-family: $main-font-regular;
    font-size: 24px;
    letter-spacing: 0.1px;
    line-height: 32px;
  }
  &__copy {
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    max-width: 400px;
    width: 100%;
    padding: 25px 0 5px;
    @include breakpoint($medium-up) {
      font-size: 20px;
      line-height: 1.4;
    }
    .offer-code {
      margin: 8px 0;
    }
  }
  &__image {
    height: auto;
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      margin-bottom: 0;
    }
    img {
      max-width: 100%;
      width: auto;
      @include breakpoint($large-up) {
        max-width: none;
      }
    }
  }
  &__continue--cta,
  &__copy--cta,
  &__copied--cta {
    display: inline-flex;
    width: 100%;
    @include breakpoint($large-up) {
      width: auto;
    }
    a {
      font-size: 13px;
      height: 60px;
      line-height: 60px;
      width: 100%;
      @include breakpoint($large-up) {
        letter-spacing: 0.4px;
        min-width: 180px;
      }
    }
  }
  &__continue--cta {
    margin-top: 12px;
    @include breakpoint($large-up) {
      margin-top: 0;
    }
  }
  &__copied--cta {
    @include breakpoint($large-up) {
      margin-right: -4px;
    }
  }
  &__copy--cta,
  &__copied--cta {
    @include button--secondary;
    font-size: 13px;
    height: 60px;
    line-height: 57px;
    @include breakpoint($large-up) {
      letter-spacing: 0.4px;
      min-width: 180px;
    }
  }
  &__cta--wrapper {
    bottom: 50px;
    #{$ldirection}: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    @include breakpoint($medium-up) {
      width: auto;
    }
    @include breakpoint($ipad-pro-portrait-only) {
      display: block;
      width: 100%;
    }
    .header-offers-panel-item__copy--cta,
    .header-offers-panel-item__continue--cta,
    .header-offers-panel-item__copied--cta {
      @include breakpoint($ipad-pro-portrait-only) {
        display: block;
        margin: 20px auto;
        width: 100%;
      }
    }
    .copied-code-btn {
      background: $color-cream-disabled;
      box-shadow: inset 0 0 0 1px $color-cream-disabled;
      cursor: auto;
      pointer-events: none;
    }
  }
  &.center-align,
  &.center-full-align {
    text-align: center;
    .header-offers-panel-item__cta--wrapper {
      padding: 0;
    }
    .header-offers-panel-item__copy {
      margin: 0 auto;
    }
    .header-offers-panel-item__header,
    .header-offers-panel-item__copy {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  &.center-full-align,
  &.left-full-align {
    padding: 105px 0 50px;
    .header-offers-panel-item__header,
    .header-offers-panel-item__copy {
      margin: 0;
      padding: 0 20px;
      @include breakpoint($large-up) {
        margin: 0 auto;
        padding: 0;
      }
    }
    .header-offers-panel-item__cta--wrapper {
      padding: 0 15px;
      @include breakpoint($large-up) {
        padding: 0;
      }
    }
    .header-offers-panel-item__image {
      height: 155px;
      @include breakpoint($large-up) {
        height: 223px;
      }
    }
  }
  &.left-full-align {
    .header-offers-panel-item__header,
    .header-offers-panel-item__copy {
      @include breakpoint($large-up) {
        margin: 0 50px;
        padding: 0;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.active-content-panel.offers-slide-panel-open {
  .slide_panel_block {
    &__reveal-content {
      max-width: 630px;
      overflow: hidden;
      width: 100%;
      @include breakpoint($medium-up) {
        width: 43%;
      }
      @include breakpoint($ipad-pro-portrait-only) {
        max-width: 834px;
        width: 100%;
      }
      &-inner {
        height: 100%;
        .contextual-links-region,
        .block-nodeblock,
        .node-elc-nodeblock {
          height: 100%;
        }
      }
    }
    &__reveal-close {
      #{$rdirection}: 16px;
      height: 42px;
      padding: 0;
      top: 17px;
      width: 42px;
    }
    &__reveal-backdrop {
      opacity: 0.4;
    }
  }
}
